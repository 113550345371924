import { ProductStockStatus } from '@/api'
import { ConfigurableVariantsData } from '@/common/types/product-types'

export const obsoleteGetConfigurableProductVariantByHash = (
  configurableVariants: ConfigurableVariantsData | null | undefined,
  hash: string,
) => {
  const numberHash = +hash

  if (Number.isNaN(numberHash)) {
    return undefined
  }

  return configurableVariants?.find(
    (variant) =>
      variant?.product?.id === numberHash &&
      variant?.product?.enabled &&
      variant?.product?.stock_status === ProductStockStatus.InStock,
  )
}
