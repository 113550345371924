'use client'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { StarIcon } from '../icons/svg'
import { selectors } from '@/common/constants/selectors-constants'

interface RatingProps {
  className?: string
  voteCountClassName?: string
  // value between 0-100 representing the percentage of stars to show
  value: number
  // when the entire component is clicked
  onClick?: () => void
  // when a star is clicked
  onChange?: (value: number) => void
  voteCount?: number
}

export const Rating = (props: RatingProps) => {
  const { className, value, onChange, onClick, voteCount, voteCountClassName } =
    props

  const [starValue, setStarValue] = useState(value)

  const rating = starValue / 20
  const valueToStarIcons = Math.floor(rating) // full icon
  const partialStarValue = Math.floor((rating % 1) * 100) // percentage of partial star icon

  const handleClick = (indexValue: number) => () => {
    if (!onClick) {
      onChange?.((indexValue + 1) * 20)
    }
  }
  const handleMouseEnter = (indexValue: number) => () => {
    if (onChange) {
      setStarValue((indexValue + 1) * 20)
    }
  }
  const handleMouseLeave = () => {
    if (onChange) {
      setStarValue(value)
    }
  }

  const wrapperClasses = twMerge(
    className,
    'flex w-fit border-transparent',
    onClick ? 'cursor-pointer hover:border-grey-300 hover:border-b' : '',
  )

  return (
    <div
      className={wrapperClasses}
      onClick={onClick}
      onMouseLeave={handleMouseLeave}
      data-test={selectors.common.rating.ratingComponent}
    >
      <div className="flex">
        {Array.from({ length: 5 }).map((_, index) => {
          const isFullStar = index < valueToStarIcons
          const isEmptyStar =
            index > valueToStarIcons ||
            (index === valueToStarIcons && !partialStarValue)

          return (
            <div
              key={index}
              className="-mx-0.5"
              onMouseEnter={handleMouseEnter(index)}
              onClick={handleClick(index)}
              {...(onChange && !onClick
                ? {
                    role: 'button',
                    'aria-label': `Rate as ${index + 1} stars`,
                    'data-test': `star-${index + 1}`,
                  }
                : {})}
            >
              <StarIcon
                className={twMerge(
                  'w-6 h-6',
                  isFullStar && 'fill-secondary',
                  isEmptyStar && 'fill-grey-300',
                )}
                partialStarValue={
                  index === valueToStarIcons && partialStarValue
                }
              />
            </div>
          )
        })}
      </div>
      {voteCount !== undefined && voteCount >= 0 && (
        <div
          className={twMerge(
            'flex items-center ml-1 text-grey-300',
            voteCountClassName,
          )}
        >
          <span className="font-bold">{value}&nbsp;%</span>
          <span className="hidden md:inline ml-1 font-bold">({voteCount})</span>
        </div>
      )}
    </div>
  )
}
