import React, { PropsWithChildren } from 'react'

interface FieldProps extends PropsWithChildren {
  label?: string | null
  required?: boolean
  error?: string
}

export const Field = ({ error, label, required, children }: FieldProps) => (
  <div className="flex flex-col">
    {label && (
      <label className="text-sm text-grey-700">
        {label}
        {required && <span className="ml-1 text-red-500">*</span>}
      </label>
    )}
    <div className="mt-1 relative">{children}</div>
    {error && <div className="text-red-500">{error}</div>}
  </div>
)
