import {
  ConfigurableVariantAttribute,
  ProductVariant,
} from '@/components/product-list-item/product-data-item-types'
import {
  ConfigurableProductFragment,
  ConfigurableProductListItemFragment,
  ConfigurableProductModalDataFragment,
  CurrencyEnum,
  GiftCardProductFragment,
  GiftCardProductListItemFragment,
  GiftCardProductModalDataFragment,
  ProductStockStatus,
} from '../api/graphql'
import { FullyRequired } from './utility-types'

export enum ProductType {
  Configurable = 'ConfigurableProduct',
  GiftCard = 'GiftCardProduct',
}

/** PRODUCT LIST TYPES */
export type ProductListItemData =
  | ConfigurableProductListItemData
  | GiftCardProductListItemData

export type ConfigurableProductListItemData =
  FullyRequired<ConfigurableProductListItemFragment>

export type GiftCardProductListItemData =
  FullyRequired<GiftCardProductListItemFragment>

/** PRODUCT MODAL TYPES */
export type ProductModalData =
  | ConfigurableProductModalData
  | GiftCardProductModalData

export type ConfigurableProductModalData =
  FullyRequired<ConfigurableProductModalDataFragment>

export type GiftCardProductModalData =
  FullyRequired<GiftCardProductModalDataFragment>

export type ConfigurableProductModalVariant = {
  is_bestseller?: boolean | null
  attributes?: Array<ConfigurableVariantAttribute> | null
  product?: ProductVariant | null
} | null

/** PRODUCT DETAIL TYPES */
export type ProductDetailData =
  | ConfigurableProductDetailData
  | GiftCardProductDetailData

export type ConfigurableProductDetailData =
  FullyRequired<ConfigurableProductFragment>

export type GiftCardProductDetailData = FullyRequired<GiftCardProductFragment>

export type ProductManufacturerInfoData = {
  __typename?: 'ManufacturerInfoInterface'
  name?: string | null
  pathname?: string | null
} | null

export type ConfigurableVariantsData = Array<{
  __typename?: 'ConfigurableVariant'
  is_bestseller?: boolean | null
  attributes?: Array<{
    __typename?: 'ConfigurableAttributeOption'
    code?: string | null
    label?: string | null
    value_index?: number | null
  } | null> | null
  product?: {
    __typename?: 'SimpleProduct'
    enabled?: boolean | null
    expiration_date?: string | null
    id?: number | null
    msrp_price?: number | null
    name?: string | null
    rating_summary: number
    review_count: number
    sku?: string | null
    stock_status?: ProductStockStatus | null
    allergens?: {
      __typename?: 'AllergensInfoInterface'
      allergens?: Array<string | null> | null
      traces?: Array<string | null> | null
    } | null
    estimated_date_of_arrival?: {
      __typename?: 'EstimatedDateOfArrivalType'
      timestamp?: string | null
      formatted_date?: string | null
    } | null
    price_tiers?: Array<{
      __typename?: 'TierPrice'
      final_price?: {
        __typename?: 'Money'
        currency?: CurrencyEnum | null
        value?: number | null
      } | null
    } | null> | null
    unit_price?: {
      __typename?: 'UnitPrice'
      value: number
      unit: string
    } | null
    visible_attributes?: Array<{
      __typename?: 'VisibleAttributes'
      label?: string | null
      code?: string | null
      values?: Array<{
        __typename?: 'VisibleAttributesValues'
        title?: string | null
        url?: string | null
        value?: string | null
      } | null> | null
    } | null> | null
    image?: {
      __typename?: 'ProductImage'
      disabled?: boolean | null
      label?: string | null
      position?: number | null
      url?: {
        __typename?: 'MediaGalleryUrlInterface'
        full?: string | null
        medium?: string | null
        small?: string | null
      } | null
    } | null
    price_range: {
      __typename?: 'PriceRange'
      maximum_price?: {
        __typename?: 'ProductPrice'
        final_price: {
          __typename?: 'MoneyTaxExtended'
          currency?: CurrencyEnum | null
          value?: number | null
          value_excl_tax?: number | null
        }
        regular_price: {
          __typename?: 'MoneyTaxExtended'
          currency?: CurrencyEnum | null
          value?: number | null
          value_excl_tax?: number | null
        }
      } | null
      minimum_price: {
        __typename?: 'ProductPrice'
        final_price: {
          __typename?: 'MoneyTaxExtended'
          currency?: CurrencyEnum | null
          value?: number | null
          value_excl_tax?: number | null
        }
      }
    }
  } | null
} | null> | null

export type ProductListItemType = {
  formatted_price: string | null
  id: number | null
  image: string | null
  is_saleable: string | null
  name: string | null
  price: number | null
  product_url: string | null
  rating_summary: string | null
  reviews_count: string | null
  skus: Array<string | null> | null
  small_image: string | null
  thumbnail: string | null
  am_labels: Array<{
    image: string | null
    image_size: string | null
    label_text: string | null
    position: number | null
    style: string | null
    type: string | null
  } | null> | null
  manufacturer: {
    id: number | null
    name: string | null
  } | null
  default_category: {
    id: number | null
    name: string | null
  } | null
  breadcrumb_en: string | null
  product_labels:
    | {
        bg: string
        content: string
        discount: null
        label: number
        src: string
        type: string
      }[]
    | null
}

export type ThumbnailData = {
  __typename?: 'ProductImage'
  label?: string | null
  url?: {
    __typename?: 'MediaGalleryUrlInterface'
    medium?: string | null
  } | null
} | null

export type PriceRangeData = {
  __typename?: 'PriceRange'
  maximum_price?: {
    __typename?: 'ProductPrice'
    final_price: {
      __typename?: 'MoneyTaxExtended'
      currency?: CurrencyEnum | null
      value?: number | null
      value_excl_tax?: number | null
    }
    regular_price: {
      __typename?: 'MoneyTaxExtended'
      currency?: CurrencyEnum | null
      value?: number | null
      value_excl_tax?: number | null
    }
  } | null
  minimum_price: {
    __typename?: 'ProductPrice'
    final_price: {
      __typename?: 'MoneyTaxExtended'
      currency?: CurrencyEnum | null
      value?: number | null
      value_excl_tax?: number | null
    }
  }
}

export type GiftCardAmountsData = Array<{
  __typename?: 'GiftCardAmounts'
  uid: string
  website_value?: number | null
} | null> | null

export function obsoleteIsGiftCardProductListItem(
  productData: ConfigurableProductListItemData | GiftCardProductListItemData,
): productData is GiftCardProductListItemData {
  return productData?.__typename === ProductType.GiftCard
}

export function obsoleteIsConfigurableProductListItem(
  productData: ConfigurableProductListItemData | GiftCardProductListItemData,
): productData is ConfigurableProductListItemData {
  return productData?.__typename === ProductType.Configurable
}

export function isGiftCardProductDetail(
  productData: ConfigurableProductFragment | GiftCardProductFragment,
): productData is GiftCardProductFragment {
  return productData?.__typename === ProductType.GiftCard
}

export function isConfigurableProductDetail(
  productData: ConfigurableProductFragment | GiftCardProductFragment,
): productData is ConfigurableProductFragment {
  return productData?.__typename === ProductType.Configurable
}
