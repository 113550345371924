'use client'
import React, { FormEvent, useCallback, useState } from 'react'
import { useTranslations } from 'next-intl'

import { useStockSubscribeMutation } from '@/api'
import { Box } from '@/components/box'
import { Button } from '@/components/button'
import { Input } from '@/components/input'
import { useAuthContext, useFlashMessagesContext } from '@/providers'
import { validateEmail } from '@/utils'
import { useValidateInput } from '@/common/hooks/use-validate-input'

export type StockSubscribeFormProps = {
  productId: number
  buttonLabel: string
  buttonClasses: string
  wrapperClasses: string
  handleFormSubmit?: () => void
}

export function StockSubscribeForm({
  productId,
  buttonLabel,
  buttonClasses,
  wrapperClasses,
  handleFormSubmit,
}: StockSubscribeFormProps): JSX.Element {
  const t = useTranslations('Product')
  const { addFlashMessage } = useFlashMessagesContext()
  const { customerData } = useAuthContext()
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const { mutateAsync: stockSubscribeMutateAsync } = useStockSubscribeMutation()
  const customerEmail = customerData?.email ?? undefined

  const {
    value: enteredEmail,
    isIncorrect: enteredEmailIsIncorrect,
    isEmpty: emailInputIsEmpty,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useValidateInput(validateEmail)

  const submitFormHandler = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setIsFormSubmitting(true)

      const stockSubscribeData = await stockSubscribeMutateAsync({
        productId: `${productId}`,
        email: customerEmail ?? enteredEmail,
      })

      if (stockSubscribeData.AmxnotifStockSubscribe?.success) {
        emailReset()
        customerEmail
          ? addFlashMessage({
              severity: 'success',
              text: t('detail.notification.flashMessage.successLoggedIn'),
            })
          : addFlashMessage({
              severity: 'success',
              text: t('detail.notification.flashMessage.successGuest'),
            })
      } else {
        const error =
          stockSubscribeData.AmxnotifStockSubscribe?.message ?? 'Error'
        addFlashMessage({ severity: 'error', text: error })
      }

      setIsFormSubmitting(false)
      handleFormSubmit?.()
    },
    [
      addFlashMessage,
      customerEmail,
      emailReset,
      enteredEmail,
      productId,
      stockSubscribeMutateAsync,
      t,
      handleFormSubmit,
    ],
  )

  const validationVariants =
    emailInputIsEmpty || enteredEmailIsIncorrect ? 'error' : 'normal'

  const buttonDisabled =
    isFormSubmitting || emailInputIsEmpty || enteredEmailIsIncorrect

  return (
    <form
      id="form-validate-stock"
      method="post"
      onSubmit={submitFormHandler}
      className="w-full"
    >
      {!customerEmail && (
        <Input
          id="email"
          type="email"
          name="email"
          required
          variant={validationVariants}
          placeholder="example@email.com"
          className="px-3.5 border-2 outline-none w-full"
          disabled={isFormSubmitting}
          value={enteredEmail}
          onBlur={emailBlurHandler}
          onChange={emailChangeHandler}
        />
      )}

      {emailInputIsEmpty && (
        <p className="text-secondary">
          {t('detail.notification.input.required')}
        </p>
      )}

      {enteredEmailIsIncorrect && (
        <p className="text-secondary">
          {t('detail.notification.input.invalid')}
        </p>
      )}

      {!emailInputIsEmpty && !enteredEmailIsIncorrect && (
        <p className="h-[21px]" />
      )}

      <Box className={wrapperClasses}>
        <Button
          type="submit"
          size="sm"
          variant="secondary"
          disabled={buttonDisabled}
          className={buttonClasses}
        >
          <span className="text-white font-bold">{buttonLabel}</span>
        </Button>
      </Box>
    </form>
  )
}
