import { useCallback } from 'react'

export function useClickOutside() {
  /**
   * Detects click outside of the target element and calls the provided callback
   * @important do not forget to call reset function when the component is unmounted
   */
  const registerClickOutsideDetection = useCallback(
    (targetElement: HTMLElement | null, onClickOutside: () => void) => {
      const handleClickOutside = (event) => {
        if (targetElement && !targetElement.contains(event.target)) {
          onClickOutside()
        }
      }

      document.addEventListener('click', handleClickOutside)

      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    },
    [],
  )

  return { registerClickOutsideDetection }
}
