'use client'

import { useTranslations } from 'next-intl'
import { twMerge } from 'tailwind-merge'

import { useBrowserMediaQuery } from '@/common/hooks/use-browser-media-query'
import { StockSubscribeForm } from '@/components/stock-subscribe-form'
import { useProductDataContext } from '@/providers/product-data/obsolote-product-data-context'

export const ProductDetailStockSubscribe = () => {
  const t = useTranslations('Product')
  const { configurableProductVariant } = useProductDataContext()
  const isMd = useBrowserMediaQuery({ breakpoint: 'md' })

  const productId = configurableProductVariant?.product?.id

  if (!productId) {
    return null
  }

  return (
    <div className="mb-5">
      <p className="my-5 block font-normal">{t('detail.notification.text')}</p>
      <div className="notification-container inline-flex flex-col items-baseline md:w-8/12 w-full">
        <StockSubscribeForm
          productId={productId}
          buttonLabel={t('detail.notification.button')}
          wrapperClasses="justify-start"
          buttonClasses={twMerge('h-12 px-4 mt-6', isMd ? 'w-full' : '')}
        />
      </div>
    </div>
  )
}
